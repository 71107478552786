<template>
	<div>
		<StaticPlayer
			v-if="!track1 && !track2 && !track3 && !track4"
			title="Listen to a track below"
			link="https://soundcloud.com/ryangled"
			img="https://i1.sndcdn.com/avatars-PnqIDFtzGofPsGnN-Nj9IMw-t500x500.jpg"
		/>

		<PlayerItem
			v-if="track1"
			title="Wanna Get To Know"
			link="https://soundcloud.com/ryangled/wanna-get-to-know"
			img="https://i1.sndcdn.com/artworks-iPGssupubKCVeqyX-zNrdoA-t500x500.jpg"
			:audio="this.$data.audio1"
		/>

		<PlayerItem
			v-if="track2"
			title="Dancing In The Street"
			link="https://soundcloud.com/ryangled/dancing-in-the-street"
			img="https://i1.sndcdn.com/artworks-HvMau5T2Q3OOPJQW-OWZTPQ-t500x500.jpg"
			:audio="this.$data.audio2"
		/>

		<PlayerItem
			v-if="track3"
			title="Running"
			link="https://soundcloud.com/ryangled/running"
			img="https://i1.sndcdn.com/artworks-9zdAG3y8MwN1ImwG-gAdUaQ-t500x500.jpg"
			:audio="this.$data.audio3"
		/>

		<PlayerItem
			v-if="track4"
			title="New York Steppin'"
			link="https://soundcloud.com/ryangled/new-york-steppin"
			img="https://i1.sndcdn.com/artworks-TEiYB3aPw79cY71N-7CMxQQ-t500x500.jpg"
			:audio="this.$data.audio4"
		/>
	</div>
</template>

<script>
	import PlayerItem from '@/components/PlayerItem.vue'
	import StaticPlayer from '@/components/StaticPlayer.vue'

	export default {
		name: 'Player',
		components: {
			PlayerItem,
			StaticPlayer,
		},

		data() {
			return {
				audio1: '/assets/audio/wanna-get-to-know.wav',
				audio2: '/assets/audio/dancing-in-the-street.wav',
				audio3: '/assets/audio/wanting-you.wav',
				audio4: '/assets/audio/dont-talk.wav'
			}
		},

		props: {
			track1: {
				type: Boolean,
				default: false,
			},

			track2: {
				type: Boolean,
				default: false
			},

			track3: {
				type: Boolean,
				default: false
			},

			track4: {
				type: Boolean,
				default: false
			},
		},
	}
</script>

<template>
	<div>
		<!-- Top Title + CTA -->
		<div
			class="
				mb-1 2xl:flex 2xl:items-center 2xl:justify-between 2xl:mb-2
			"
		>
			<div
				class="
					flex items-center space-x-2
					2xl:flex-col 2xl:items-start 2xl:space-x-0
				"
			>
				<h1 class="text-2xl mb-6 2xl:flex 2xl:flex-col 2xl:mb-0">
					{{ title }}
				</h1>
			</div>
		</div>

		<!-- Artwork -->
		<div
			class="
				relative cursor-pointer mb-6 rounded-lg group
				border border-white border-opacity-25 bg-black bg-opacity-60
				md:mb-4
			"
		>
			<div>
				<a
					:href="$props.link"
					target="_data"
					class="
						opacity-0 absolute h-full
						transform duration-300 z-10 rounded-lg
						w-full flex items-center justify-center
						group-hover:opacity-100 group-hover:bg-black group-hover:bg-opacity-60
					"
				>
					{{ hoverLink }} <i class="ml-1 fab fa-soundcloud"></i>
				</a>

				<div>
					<img
						class="
							player-img rounded-lg shadow-xl
							md:p-4 md:rounded-full
						"
						:src="$props.img"
						:alt="$props.title"
					>
				</div>
			</div>
		</div>

		<!-- Bottom Like + Follow -->
		<div
			class="
				flex items-center justify-between text-sm mb-4
				md:mb-0
			"
		>
			<a
				:href="$props.link"
				target="_data"
				class="hover:opacity-75 transform duration-300"
			>
				Follow on Soundcloud
			</a>

			<a
				href="https://www.instagram.com/ryangled/"
				class="hover:opacity-75 transform duration-300"
			>
				Follow on <i class="fab fa-instagram fa-sm ml-1"></i>
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: String,

			link: String,

			img: String,

			audio: String,

			cta: {
				type: String,
				default: 'Listen to the full track'
			},

			hoverLink: {
				type: String,
				default: 'View Soundcloud profile'
			},
		},
	}
</script>

<style scoped>
	.player-img {
		width: 650px;
	}
</style>

<template>
	<header
		class="
			w-full fixed top-0 left-0 flex justify-between p-4 text-sm
			bg-black border-b border-white border-opacity-25 shadow-lg
			md:px-16 md:py-4 z-10
		"
	>
		<a
			class="uppercase hover:opacity-75 transform duration-300"
			:href="$props.scLink"
			target="_data"
		>
			{{ title }}
		</a>

		<span>
			{{ find }}

			<a
				class="sc hover:opacity-75 transform duration-300"
				:href="$props.scLink"
				target="_data"
			>
				{{ sc }} <i class="fab fa-soundcloud"></i>
			</a>
		</span>
	</header>
</template>

<script>
	export default {
		name: 'Header',
		props: {
			title: String,

			find: String,

			sc: {
				type: String,
				default: 'Soundcloud',
			},

			scLink: {
				type: String,
				default: 'https://soundcloud.com/ryangled',
			}
		},
	}
</script>

<template>
	<footer
		class="
			text-center py-8 text-sm px-4 bg-black
			md:px-16 md:py-4 md:border-t md:border-white md:border-opacity-25 md:fixed md:bottom-0 md:w-full
		"
		>
		<span>
			{{ title }}

			<a
				class="sc hover:opacity-75 transform duration-300"
				:href="$props.scLink" 
				target="_data"
			>
				{{ sc }} <i class="fab fa-soundcloud"></i>
			</a>
		</span>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
		props: {
			title: String,

			sc: {
				type: String,
				default: 'Soundcloud',
			},

			scLink: {
				type: String,
				default: 'https://soundcloud.com/ryangled',
			}
		},
	}
</script>

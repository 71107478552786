<template>
	<div class="w-screen h-screen relative mx-auto max-w-lg md:max-w-full">
		<Header
			title="Ryan Gled"
			find="Find me on"

		/>

		<div
			class="
				md:flex md:justify-center md:items-center md:flex-row-reverse
				md:h-screen
			"
		>
			<Player
				class="
					md:w-3/6 lg:w-2/6
					mt-20 px-4 md:px-0 md:mt-0
				"
				:track1="track1"
				:track2="track2"
				:track3="track3"
				:track4="track4"
			/>

			<List
				class="
					w-full list
					md:w-1/4 md:mr-10
				"
				v-on:track1Active="showTrack1($event)"
				v-on:track2Active="showTrack2($event)"
				v-on:track3Active="showTrack3($event)"
				v-on:track4Active="showTrack4($event)"

				v-on:toTop="scrollToTop($event)"
			/>
		</div>

		<Footer
			title="Listen to more on"
		/>
	</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import List from '@/components/List.vue'
import Player from '@/components/Player.vue'

export default {
	name: 'Home',
	components: {
		Header,
		Footer,
		List,
		Player,
	},

	data() {
		return {
			track1: false,
			track2: false,
			track3: false,
			track4: false,
		}
	},

	methods: {
		showTrack1(track) {
			this.track1 = track
			this.track2 = false
			this.track3 = false
			this.track4 = false
		},

		showTrack2(track) {
			this.track1 = false
			this.track2 = track
			this.track3 = false
			this.track4 = false
		},

		showTrack3(track) {
			this.track1 = false
			this.track2 = false
			this.track3 = track
			this.track4 = false
		},

		showTrack4(track) {
			this.track1 = false
			this.track2 = false
			this.track3 = false
			this.track4 = track
		},

		scrollToTop() {
			const scrollOptions = {
				top: 0,
				behavior: 'smooth'
			}
			window.scrollTo(scrollOptions);
		},
	},
}
</script>

<style>
	body {
		font-family: 'Poppins', sans-serif;
		color: white;
		background-color: #030303;
	}

	.sc {
		color: #ff5500;
	}

	.bg-sc {
		background-color: #ff5500;
	}
</style>

<template>
	<div>
		<audio :src="audio" controls autoplay class="hidden"></audio>

		<!-- Top Title + CTA -->
		<div
			class="
				mb-8
				2xl:flex 2xl:items-center 2xl:justify-between 2xl:mb-2
			"
		>
			<div
				class="
					flex items-center space-x-2
					2xl:flex-col 2xl:items-start 2xl:space-x-0
				"
			>
				<h1 class="text-2xl mb-6 2xl:flex 2xl:flex-col 2xl:mb-0">
					{{ title }}
				</h1>
				
				<span class="mb-6 sc text-sm font-bold 2xl:mb-0">
					{{ preview }}
				</span>
			</div>

			<a
				:href="$props.link"
				class="hover:opacity-75 transform duration-300 text-sm bg-sc text-white p-4 rounded-lg md:p-3"
			>
				{{ cta }}
			</a>
		</div>

		<div id="progress-bar" class="progress">
			<div class="progress-fill"></div>
		</div>

		<!-- Artwork -->
		<div
			class="
				relative cursor-pointer mb-6 rounded-lg group
				border border-white border-opacity-25 bg-black bg-opacity-60
				md:mb-4
			"
		>
			<div>
				<a
					:href="$props.link"
					target="_data"
					class="
						opacity-0 absolute h-full
						transform duration-300 z-10 rounded-lg
						w-full flex items-center justify-center
						group-hover:opacity-100 group-hover:bg-black group-hover:bg-opacity-60
					"
				>
					{{ hoverLink }} <i class="ml-1 fab fa-soundcloud"></i>
				</a>

				<div class="rotate">
					<img
						class="
							player-img rounded-lg shadow-xl
							md:p-4 md:rounded-full
						"
						:src="$props.img"
						:alt="$props.title"
					>
				</div>
			</div>
		</div>

		<!-- Bottom Like + Follow -->
		<div
			class="
				flex items-center justify-between text-sm mb-4
				md:mb-0
			"
		>
			<a
				:href="$props.link"
				target="_data"
				class="hover:opacity-75 transform duration-300"
			>
				Like <i class="sc mx-1 fas fa-heart fa-sm"></i> on Soundcloud
			</a>

			<a
				href="https://www.instagram.com/ryangled/"
				class="hover:opacity-75 transform duration-300"
			>
				Follow on <i class="fab fa-instagram fa-sm ml-1"></i>
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: String,

			link: String,

			img: String,

			audio: String,

			cta: {
				type: String,
				default: 'Listen to the full track'
			},

			preview: {
				type: String,
				default: '[PREVIEW]'
			},

			hoverLink: {
				type: String,
				default: 'Listen on Soundcloud'
			},
		},
	}
</script>

<style scoped>
	.player-img {
		width: 650px;
	}

	/* Progress bar anim */
	.progress-temp {
		position: relative;
		width: 100%;
		border: 2px solid rgb(77, 77, 77);
		border-radius: 10px;
		margin-bottom: 15px;
	}

	.progress {
		position: relative;
		width: 100%;
		border: 2px solid rgb(77, 77, 77);
		border-radius: 10px;
		margin-bottom: 15px;
	}

	.progress .progress-fill {
		position: absolute;
		background-color: #ffffff;
		width: 0px;
		height: 4px;
		margin-top: -2px;
		margin-left: -1px;
		border-radius: 15px;
		animation: progress 201s linear;
	}

	@keyframes progress {
		0% {
			width: 0%;
		}

		100% {
			width: 100%;
		}
	}

	@media only screen and (min-width: 768px) {
		.rotate {
			animation: rotate 16s infinite linear;
		}

		@keyframes rotate {
			from {
				transform: rotate(0deg);
			}
			
			to {
				transform: rotate(360deg);
			}
		}
	}
</style>

<template>
	<div class="mt-6 md:mt-0">
		<ListItem
			title="Wanna Get To Know"
			class="rounded-tr-lg rounded-tl-lg"
			src="https://i1.sndcdn.com/artworks-iPGssupubKCVeqyX-zNrdoA-t500x500.jpg"
			@click="clickTrack1()"
		/>

		<ListItem
			title="Dancing In The Street"
			src="https://i1.sndcdn.com/artworks-HvMau5T2Q3OOPJQW-OWZTPQ-t500x500.jpg"
			@click="clickTrack2()"
		/>

		<ListItem
			title="Running"
			src="https://i1.sndcdn.com/artworks-9zdAG3y8MwN1ImwG-gAdUaQ-t500x500.jpg"
			@click="clickTrack3()"
		/>

		<ListItem
			title="New York Steppin'"
			src="https://i1.sndcdn.com/artworks-TEiYB3aPw79cY71N-7CMxQQ-t500x500.jpg"
			@click="clickTrack4()"
		/>
	</div>
</template>

<script>
	import ListItem from '@/components/ListItem.vue'

	export default {
		name: 'List',
		components: {
			ListItem,
		},

		methods: {
			clickTrack1() {
				this.$emit('track1Active', true)
				this.$emit('toTop')
			},

			clickTrack2() {
				this.$emit('track2Active', true)
				this.$emit('toTop')
			},

			clickTrack3() {
				this.$emit('track3Active', true)
				this.$emit('toTop')
			},

			clickTrack4() {
				this.$emit('track4Active', true)
				this.$emit('toTop')
			},
		},
	}
</script>

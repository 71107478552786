<template>
	<div
		:class="[
			'flex space-x-4 items-center p-4 cursor-pointer',
			'border-b border-white border-opacity-25',
			'opacity-75 hover:border-opacity-100 hover:opacity-100 transform duration-300',
			'md:border-none md:rounded-full md:mb-4 md:p-2',
			'lg:p-4',
			$data.active === index ? 'bg-sc opacity-100' : 'bg-pill',
		]"
		@click="onSelect(index)"
	>
		<img
			class="w-14 h-14 md:w-12 md:h-12 rounded-full"
			:src="$props.src"
			:alt="$props.title"
		>

		<p
			class="text-md lg:text-sm"
		>
			{{ title }}
		</p>
	</div>
</template>

<script>
	export default {
		props: {
			title: String,

			src: String,
		},

		data() {
			return {
				active: 0,
			}
		},

		methods: {
			onSelect(index) {
				this.$data.active = index;
			}
		},
	}
</script>

<style scoped>
	.bg-pill {
		background: #1d1d1d;
	}
</style>
